import React, { Component } from 'react';
import debounce from 'lodash.debounce';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import GreenTick from '../GreenTick/GreenTick';
import LocationCreatorPopup from '../LocationSelector/CreatorPopup/LocationCreatorPopup';
import { Fragment } from 'react';
import { QRURLRemover, ProjectPlaceLoginCheck } from '../Utils';
import { StockLocations } from '../StockOrderButton/StockLocations'; 
import { ProjectSelector } from '../ProjectSelector/ProjectSelector';

export class GoodsInForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formSubmitted: false,
            buttonText: "Submit form",
            blockSubmission: true,
            measurementUnit: this.props.measurementUnit,
            locationBarcodeText: "",
            itemBarcodeText: "",

            itemValidated: false,
            itemFound: false,
            itemCurrentlyInStock: false,
            itemMatches: false,
            itemName: "",
            itemBarcodeNew: false,
            itemBarcode: "",
            itemEnterKeyPressed: false,

            locationLocked: false,
            locationBarcode: "",
            locationIdentifier: "",
            locationFound: false,
            locationValid: false,
            locationHasValue: false,
            locationText: "",

            amountText: "",
            amountValid: this.props.measurementUnit === "Individual",

            immediateCheckout: false,
            checkoutProjectId: 0,
            immediateCheckoutExampleBarcode: "",

            scannedItemBarcodes: []
        }
    }

    static defaultProps = {
        onSubmit: () => { },
        measurementUnit: "Item",
        itemListingId: null,
        orderId: null
    }

    componentDidMount() {
        var callbackURL = `/goods-in`;
        ProjectPlaceLoginCheck(callbackURL); //Redirect if not logged into project place

        this.itemTextDebouncer = debounce(this.itemTextDebounced, 500);
        this.locationTextDebouncer = debounce(this.locationTextDebounced, 500);
        this.itemBarcodeTextbox.focus();

        this.loadQuickCheckoutBarcodes();
    }

    async loadQuickCheckoutBarcodes() {
        const response = await fetch(`api/items/get-item-example-barcode?itemListingId=${this.props.itemListingId}`);
        const data = await response.text();
        this.setState({ immediateCheckoutExampleBarcode: data });
    }

    itemTextDebounced = (text) => {
        this.itemTextDebouncer.cancel();
        this.setState({
            itemValidated: false
        });

        var barcode = QRURLRemover(text.target.value);

        if (barcode.length >= 5) {
            if (text.target.value.startsWith("L-") && this.state.scannedItemBarcodes.length > 0) {
                //Location scanned during part multi-scan. Handle location
                this.checkLocationBarcode(text.target.value);
                this.setState({
                    locationBarcodeText: text.target.value,
                    itemBarcodeText: ""
                }, this.locationTextDebounced(text));
            }
            else {
                //Treat as a normal item barcode
                this.checkItemBarcode(barcode);
            }
            
        }        
    }

    locationTextDebounced = (text) => {
        this.setState({
            locationValid: false,
            locationText: text.target.value
        });

        if (text.target.value.length >= 5) {
            //Submit location check
            this.checkLocationBarcode(text.target.value);
            this.setState({ locationHasValue: true });
        }
        else {
            this.setState({ locationValid: false, locationHasValue: false });
        }
    }

    async checkItemBarcode(barcode) {

        if (this.props.itemListingId === null) {
            var URL = `api/items/verify-item-barcode?barcode=${barcode}`;
        }
        else {
            var URL = `api/items/verify-item-barcode?barcode=${barcode}&itemListingID=${this.props.itemListingId}`;
        }
        const response = await fetch(URL);
        const data = await response.json();

        if (data.itemFound) {
            //An item with that barcode has been found            

            this.setState({
                measurementUnit: data.measurementUnit,
                itemValidated: true,
                itemFound: true,
                itemBarcodeNew: false,
                itemName: data.itemName,
                itemBarcode: data.itemBarcode,
                itemEnterKeyPressed: false,
                itemCurrentlyInStock: data.inStock
            });

            if ((data.itemMatches || this.props.itemListingId === null)) {                
                if (data.inStock && data.measurementUnit === "Individual") {
                    //Item ok but already in stock?!?
                    this.setState({
                        itemMatches: true,
                        locationLocked: false,
                        locationValid: false,
                        locationFound: false
                    });
                }
                else {
                    //Item accepted, location accepted
                    this.setState({
                        itemMatches: true,
                        locationBarcode: data.locationBarcode,
                        locationIdentifier: data.locationIdentifier,
                        locationLocked: (data.inStock > 0 ? true: false),
                        locationFound: true,
                        locationValid: true,
                        scannedItemBarcodes: [data.itemBarcode] //Todo: Make multi scan work for known items!
                    });
                    this.amountTextbox.focus();
                }
            }
            else {
                //Item not accepted
                this.setState({
                    itemMatches: false,
                    locationLocked: false,
                    locationValid: false,
                    locationFound: false
                });
            }
        }
        else {

            //If itemListingId has not been defined, then a new barcode cannot be accepted
            if (this.props.itemListingId === null) {
                this.setState({
                    itemValidated: false,
                    itemFound: false,
                    itemBarcodeNew: true,
                    locationLocked: true,
                    locationValid: false,
                    locationFound: false,
                    itemCurrentlyInStock: false
                });
            }
            else {
                //New item, specify location
                this.setState({
                    itemValidated: true,
                    itemFound: false,
                    itemBarcodeNew: true,
                    itemMatches: "",
                    itemName: "",
                    itemBarcode: data.itemBarcode,
                    locationLocked: false,
                    locationValid: false,
                    locationFound: false,
                    itemBarcodeText: "",
                    scannedItemBarcodes: [...this.state.scannedItemBarcodes, data.itemBarcode]
                },
                    function () {
                        if (this.state.itemEnterKeyPressed) {
                            this.setState({ itemEnterKeyPressed: false });
                            this.itemBarcodeTextbox.focus();
                        }
                        if (this.state.locationBarcodeText.length > 0) {
                            this.checkLocationBarcode(this.state.locationBarcodeText);
                        }
                    }
                );
            }
        }
    }


    async checkLocationBarcode(barcode) {
        const response = await fetch(`api/location/verify-location-barcode?barcode=${barcode}`);
        const data = await response.json();

        if (data.locationFound) {
            //A location with that barcode has been found

            this.setState({
                locationBarcode: data.barcode,
                locationIdentifier: data.identifier,
                locationLocked: false,
                locationValid: true,
                locationFound: true,
            });
            if (this.state.measurementUnit !== "Individual") {
                this.amountTextbox.focus();
            }            
        }
        else {
            this.setState({
                locationBarcode: data.barcode,
                locationIdentifier: "",
                locationLocked: false,
                locationValid: data.barcodeValid,
                locationFound: false,
            });
        }
    }


    isInt(value) {
        return !isNaN(value) &&
            parseInt(Number(value)) == value &&
            !isNaN(parseInt(value));
    }

    isFloat(value) {
        return !isNaN(value) &&
            parseFloat(Number(value)) == value &&
            !isNaN(parseFloat(value));
    }

    submitHandler(e) {
        e.preventDefault();
    }

    submitForm() {
        var amount = this.state.measurementUnit === "Individual" ? 1 : this.state.amountText;

        this.sendCreateLocation(JSON.stringify({
            orderId: this.props.orderId,
            itemListingId: this.props.itemListingId,
            itemBarcodes: this.state.scannedItemBarcodes,
            locationBarcode: this.state.locationBarcode,
            amount: amount,
            measurementUnit: this.state.measurementUnit,
            immediateCheckout: this.state.immediateCheckout,
            checkoutProjectId: this.state.checkoutProjectId
        }));        
    };
    
    async sendCreateLocation(formData) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: formData
        };
        
        if (this.state.measurementUnit === "Item") {
            var numberOfItemsReceived = this.state.amountText;
        }
        else {
            var numberOfItemsReceived = 1;
        }

        const response = await fetch(`api/items/goods-in-request`, requestOptions).then(
            this.props.onSubmit(numberOfItemsReceived));
    }

    onProjectSelection = (id) => {
        this.setState({ checkoutProjectId: id });
    }

    onChangeImmediateCheckout = (event) => {
        this.setState({ immediateCheckout: event.target.checked });

        if (event.target.checked) {
            if (this.state.measurementUnit === "Individual") {
                //Is an individual item             
                this.setState({ locationBarcodeText: "L-GOODS-IN" });
            }
            else if (this.state.immediateCheckoutExampleBarcode.length > 0)
            {
                //Is not an individual item
                this.checkItemBarcode(this.state.immediateCheckoutExampleBarcode);
            }

        }
    };

    render() {
        const submitButtonClicked = () => {
            this.setState({
                buttonText: "Please wait...",
                formSubmitted: true
            });
            this.submitForm();
        }

        const onItemTextChange = (event) => {
            this.setState({
                itemBarcodeText: QRURLRemover(event.target.value)
            })

            if (event.target.value.length == 0 && this.state.scannedItemBarcodes > 0) {
                return;
            }
            else {
                //Don't update if text is empty and barcodes have been scanned
                this.itemTextDebouncer(event);
            }            
        };

        const onLocationTextChange = (event) => {
            this.setState({
                locationBarcodeText: event.target.value
            })
            this.locationTextDebouncer(event);
        };

        //Amount textbox / button
        const onAmountChange = (event) => {

            var isValid = false;

            if (this.state.measurementUnit === "Item") {
                if (this.isInt(event.target.value)) {
                    isValid = true;
                }
            }
            else {
                isValid = true;
            }

            this.setState({
                amountText: event.target.value,
                amountValid: isValid
            });
        };

        const setFullAmount = () => {
            this.setState({
                amountText: this.props.itemDimensions,
                amountValid: true
            });
        };


        const handleEnterOnItemBarcode = (event) => {
            if (event.key.toLowerCase() === "enter") {
                this.setState({ itemEnterKeyPressed: true }, function () { this.itemTextDebounced(event) });
            }
        };

        const reCheckLocationBarcode = () => {
            this.checkLocationBarcode(this.state.locationText);
        }

  

        //ItemTextBox
        var itemTextBox = "";
        if (this.state.itemValidated) {
            if (this.state.itemFound) {
                if (!this.state.itemMatches) {
                    var itemTextBox = <p>❌ Invalid, barcode matches another part: <b>{this.state.itemName}</b></p>
                } else {
                    if (this.state.itemCurrentlyInStock) {
                        var itemTextBox = <p>❌ Item already in stores?!?</p>
                    }
                }
            }
        }
        else if (this.state.itemBarcodeNew && this.props.itemListingId === null) {
            //Validated = false, itemBarcodeNew true
            var itemTextBox = <p>❌ Item does not exist, quick goods-in cannot be used.</p>
        }
        

        //Location
        var locationTextBox = "";
        var showLockedLocationTextBox = false;
        if (this.state.locationLocked) {
            showLockedLocationTextBox = true;
            var locationText = this.state.locationBarcode + " - " + this.state.locationIdentifier
            var locationTextBox =
                <div style={{ paddingRight: "10px" }}>
                    <GreenTick />
                </div>
        }
        else {
            if (this.state.locationFound) {
                var textWidth = Math.min(this.state.locationIdentifier.length, 30) + "ch"
                var locationTextBox =
                    <div style={{ display: "flex", paddingLeft: "10px", verticalAlign: "middle" }}>
                        <p style={{ width: textWidth, verticalAlign: "bottom", fontSize: "14px", margin: "0px", padding: "0px", paddingTop: "5px" }}>{this.state.locationIdentifier}</p>
                        <GreenTick />
                    </div>
            }
            else if (this.state.locationValid) {
                var locationTextBox =
                    <div style={{ display: "flex", paddingLeft: "10px", verticalAlign: "middle", width: "200px" }}>
                        <LocationCreatorPopup triggerText={"Create new"} locationBarcode={this.state.locationText} onSubmit={reCheckLocationBarcode} />
                    </div>
            }
            else if (this.state.locationHasValue) {
                var locationTextBox =
                    <p style={{ width: "20ch", verticalAlign: "bottom", fontSize: "14px", margin: "0px", padding: "0px", paddingTop: "5px" }}>❌ <b>Invalid location</b></p>
            }

        }

        var amountTextStyle = {}
        if (!this.state.amountValid && this.state.locationFound) {
            amountTextStyle = {backgroundColor: "#ffe0e0"}
        }


       
        return (
            <form onSubmit={this.submitHandler}>
                <hr/>
                <div classname="form-group">
                    <div className="form-check form-switch">
                        <label htmlFor="immediate_checkout">Check item out to a project immediately</label> <input className="form-check-input" type="checkbox" id="immediateCheckout" onChange={this.onChangeImmediateCheckout} checked={this.state.immediateCheckout} />
                    </div>
                    {this.state.immediateCheckout ?
                        <div><ProjectSelector onSelection={this.onProjectSelection} /></div>
                        :
                        null
                    }
                </div>
                <div className="form-group">
                    <hr />
                    <label htmlFor="itemBarcode">Item barcode</label>
                    <input type="hidden" id="itemBarcode" value={this.state.itemBarcode} />
                    {this.state.scannedItemBarcodes.map(barcode => <div key={barcode} style={{ display: "flex" }}><input className="form-control" id="itemBarcode-TEXTAREA" value={barcode} disabled /><div style={{ paddingRight: "10px" }}><GreenTick /></div></div>)}
                    {this.state.locationValid && this.state.measurementUnit !== "Individual" ?
                        null //TODO: Add ability to multi scan when a location is known
                        :
                        <div style={{ display: "flex" }}>
                            <input className="form-control" id="itemBarcode" onChange={onItemTextChange} value={this.state.itemBarcodeText} ref={(n) => { this.itemBarcodeTextbox = n; }} onKeyPress={handleEnterOnItemBarcode} required />
                            {itemTextBox}
                        </div>                       
                    }
                </div>

                {!showLockedLocationTextBox && this.props.itemListingId != null ?
                    <div>
                        <hr />
                        <StockLocations minimalView={true} itemListingId={this.props.itemListingId} />
                        <hr />
                    </div>
                    :
                    null
                }

                <div className="form-group">
                    <label htmlFor="locationBarcode">Location Barcode (or additional item)</label>
                    <div style={{ display: "flex" }}>
                        <input type="hidden" id="locationBarcode" value={this.state.locationBarcode} />
                        {showLockedLocationTextBox ?
                            <input className="form-control" id="locationBarcode-TEXTAREA" disabled value={locationText} />
                            :
                            <input className="form-control" id="locationBarcode" onChange={onLocationTextChange} required disabled={!(this.state.itemValidated && this.state.itemBarcodeNew)} ref={(n) => { this.locationBarcodeTextbox = n; }} value={this.state.locationBarcodeText} />
                        }
                        {locationTextBox}
                    </div>
                </div>
                {this.state.measurementUnit === "Individual" ?
                    <div className="form-group">
                        <label htmlFor="amount"><b>Item must be individually barcoded</b></label>
                    </div>
                    :
                    <div className="form-group">
                        <label htmlFor="amount">How many?</label>
                        <div style={{ display: "flex" }}>
                            <input type="number" min="0" max={this.props.itemDimensions} step={this.state.measurementUnit === "Item" ? "1" : "any"} className="form-control" value={this.state.amountText} onChange={onAmountChange} style={amountTextStyle} id="amount" ref={(n) => { this.amountTextbox = n; }} disabled={!this.state.locationFound} required />
                            {this.props.itemDimensions != null ?
                                <Fragment>
                                    <div style={{ paddingRight: "10px" }} />
                                    <Button type="button" variant="outline-primary" style={{ width: "200px" }} onClick={setFullAmount} disabled={!this.state.locationFound}> All {this.props.itemDimensions} {this.state.measurementUnit}s</Button>
                                </Fragment>
                                :
                                null}
                        </div>
                    </div>
                }                
                <br />
                <div className="form-group">

                    <button disabled={this.state.formSubmitted || !(this.state.scannedItemBarcodes.length > 0 && this.state.locationFound && (this.state.amountValid || this.state.measurementUnit === "Individual")) || (this.state.immediateCheckout && this.state.checkoutProjectId === 0)} onClick={submitButtonClicked} className="form-control btn btn-primary" type="submit">
                        {this.state.buttonText}
                    </button>
                </div>
            </form>
        );
    }
}