import React, { Component, useCallback } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { QRURLRemover } from './Utils';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import 'bootstrap/dist/css/bootstrap.min.css';
import QuantityFormModal from './QuantitySelector/Modal';

export class JournalItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasResults: false,
            loading: false,
            barcodeTextFrom: "",
            barcodeTextTo: "",
            item: null,
            errorMessage: "",
            ShowQuantityPopup: false
        }
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.closeQuantityForm = this.closeQuantityForm.bind(this);
        this.quantityFormSubmitted = this.quantityFormSubmitted.bind(this);
    }

    componentDidMount() {
        this.barcodeBarFrom.focus();
    }

    async onFormSubmit(event) {
        event.preventDefault();

        const [responseFrom, responseTo] = await Promise.all([
            fetch(`api/items/get-item-from-barcode?barcode=${this.state.barcodeTextFrom}`),
            fetch(`api/items/get-item-from-barcode?barcode=${this.state.barcodeTextTo}`)
        ]);
        
        const jsonTo = await responseTo.json();        
        const jsonFrom = await responseFrom.json();        
        
        if ((jsonFrom.itemListing === null) || (jsonTo.itemListing === null)) {
            //Invalid barcode without an entry
            this.setState({ errorMessage: "Error: One of the barcodes does not exist.", showQuantityPopup: false });
        }
        else {
            if (jsonFrom.itemListing.MeasurementUnit === "Individual") {
                this.setState({ errorMessage: "Error: Cannot journal individual items", showQuantityPopup: false });
            }
            else {
                //Check that they are the same itemListing
                if (jsonTo.itemListing.Id === jsonFrom.itemListing.Id) {
                    //Get Quantity
                    this.setState({ errorMessage: "", showQuantityPopup: true, item: jsonFrom });
                }
                else {
                    this.setState({ errorMessage: "Error: Those two items are not the same.", showQuantityPopup: false });
                }
            }
        }
    }

    closeQuantityForm() {
        this.setState({ showQuantityPopup: false }, () => { this.barcodeBarFrom.focus() });
    };

    async quantityFormSubmitted(amount) {

        var json = JSON.stringify({            
            barcodeFrom: this.state.barcodeTextFrom,
            barcodeTo: this.state.barcodeTextTo,
            amount: amount
        });

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: json
        };
        const response = await fetch(`api/items/journal-stock`, requestOptions);
        const data = await response.json();

        if (data.success) {
            alert("Done!");

            this.setState({
                barcodeTextFrom: "",
                barcodeTextTo: "",
                ShowQuantityPopup: false
            });
        }
        else {
            alert("ERROR " + data.message);
            alert("ERROR " + data.message);
        }
        this.closeQuantityForm();
    }

    render() {        
        const onBarcodeTextChangeFrom = (event) => {
            this.setState({ barcodeTextFrom: QRURLRemover(event.target.value) });
        }

        const onBarcodeTextChangeTo = (event) => {
            this.setState({ barcodeTextTo: QRURLRemover(event.target.value) });
        }
        return (
            <div>
                {this.state.showQuantityPopup ?
                    <QuantityFormModal closeModal={this.closeQuantityForm} onSubmit={this.quantityFormSubmitted} itemName={this.state.item.itemListing.name} maxAmount={this.state.item.amountInStock} measurementUnit={this.state.item.itemListing.measurementUnit} />
                    : null
                }
                <center>
                    {this.state.errorMessage.length > 0 ? <center><p style={{ fontSize: "20px", color: "red" }}>{this.state.errorMessage}</p></center> : null}
                    <div style={{ display: 'inline-block', paddingLeft: "10px", verticalAlign: "bottom", height: "80px" }}>
                        <form onSubmit={this.onFormSubmit}>
                            Move from barcode: <input onChange={onBarcodeTextChangeFrom} value={this.state.barcodeTextFrom} id="barcodeFrom" autoComplete="off" size="30" style={{ height: "41px" }} ref={(n) => { this.barcodeBarFrom = n; }} /> <br />
                            To Barcode: <input onChange={onBarcodeTextChangeTo} value={this.state.barcodeTextTo} id="barcodeTo" autoComplete="off" size="30" style={{ height: "41px" }} ref={(n) => { this.barcodeBarTo = n; }} /><br />
                            <Button type="submit" variant="outline-secondary" disabled={this.state.loading}>Submit</Button>
                        </form>
                    </div>
                </center>
            </div>
        );
    }
}