import React, { Component, Fragment } from 'react';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Spinner } from 'reactstrap';
import QuantityFormModal from './QuantitySelector/Modal';
import { ItemListView } from './ItemListing/ItemListView';
import './NavMenu.css';
import { QRURLRemover } from './Utils';
import { v4 as uuidv4 } from 'uuid';
import { ProjectSelector } from './ProjectSelector/ProjectSelector';
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import TransactionUndo from './ItemListing/TransactionUndo';

export class Checkout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            processing: false,
            showQuantityPopup: false,
            barcodeText: "",
            showError: false,
            errorMessage: "",
            transactionUUID: uuidv4(),

            selectedProjectID: 0,

            newItem: {},
            quantityFormItemName: "",
            quantityFormMaxAmount: "",
            quantityFormMeasurementUnit: "Item",

            processedItems: []
        }

        this.submitHandler = this.submitHandler.bind(this);
        this.closeQuantityForm = this.closeQuantityForm.bind(this);
        this.quantityFormSubmitted = this.quantityFormSubmitted.bind(this);
        this.onProjectSelection = this.onProjectSelection.bind(this);
        this.deleteEntry = this.deleteEntry.bind(this);
    }

    submitHandler(e) {
        e.preventDefault();
        this.setState({ processing: true, showError: false });
        this.getItem(e.target.barcode.value);
        this.barcodeTextbox.focus();
    };

    async getItem(barcode) {
        const response = await fetch(`api/items/get-item-from-barcode?barcode=${barcode}`);
        const data = await response.json();
        if (data.id === 0) {
            this.setState({
                processing: false,
                showError: true,
                barcodeText: "",
                errorMessage: "❌ - \"" + this.state.barcodeText + "\" is an invalid barcode!"
            });
            this.barcodeTextbox.focus();
        }
        else {
            if (data.amountInStock <= 0) {
                this.setState({
                    processing: false,
                    showError: true,
                    barcodeText: "",
                    errorMessage: "❌ - \"" + data.itemListing.name + "\" (" + this.state.barcodeText + ") is already out of stock?!?!"
                });
                this.barcodeTextbox.focus();
            }
            else {
                if (data.itemListing.measurementUnit === "Individual") {
                    //All good, only one *item* available, so do a quick-checkout
                    this.setState({
                        newItem: data,
                        quantityFormItemName: data.itemListing.name,
                        quantityFormMaxAmount: data.amountInStock,
                        quantityFormMeasurementUnit: data.itemListing.measurementUnit,
                        quantityItemId: data.id,
                        showQuantityPopup: false
                    }, () => { this.quantityFormSubmitted(1) });
                }
                else {
                    //All good, open the quantity form
                    this.setState({
                        newItem: data,
                        quantityFormItemName: data.itemListing.name,
                        quantityFormMaxAmount: data.amountInStock,
                        quantityFormMeasurementUnit: data.itemListing.measurementUnit,
                        quantityItemId: data.id,
                        showQuantityPopup: true
                    });
                }
            }            
        }
    }

    closeQuantityForm() {
        this.setState({ showQuantityPopup: false, processing: false, barcodeText: "" }, () => { this.barcodeTextbox.focus() });
    };

    async quantityFormSubmitted(amount) {

        var json = JSON.stringify({
            amount: amount,
            itemId: this.state.quantityItemId,
            transactionUUID: this.state.transactionUUID,
            projectId: this.state.selectedProjectID
        });

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: json
        };
        const response = await fetch(`api/items/check-out`, requestOptions);
        const data = await response.json();

        if (data.success) {
            var thisItem = {
                amount: amount, item: this.state.newItem, id: data.id
            }

            this.setState({
                processedItems: [thisItem, ...this.state.processedItems],
            });            
        }
        this.closeQuantityForm();
    }

   

    renderProcessedTransactions(transactions) {
        const customAnimation = keyframes`
              from {
                opacity: 0;
                transform: translate3d(0px, -30px, 0px);
              }

              to {
                opacity: 1;
                transform: translate3d(0, 0, 0);
              }
              `;
        return (
            <Reveal keyframes={customAnimation} cascade>
                {transactions.length > 0 ?
                    transactions.map(transaction => <ItemListView key={transaction.id} item={transaction.item.itemListing} quantityHint={transaction.amount} hideStockButton={true} buttonComponent={<TransactionUndo id={transaction.id} transactionUUID={this.state.transactionUUID} itemName={transaction.item.itemListing.name} onSubmit={this.deleteEntry} />} />)
                    :
                    <center><p>No items checked out yet...</p></center>
                }
            </Reveal>
        );
    }

    onProjectSelection = (id) => {
        this.setState({ selectedProjectID: id }, this.barcodeTextbox.focus());
    }

    deleteEntry(transactionId) {
        var index = this.state.processedItems.findIndex(x => x.id === transactionId);

        let items = [...this.state.processedItems];
        items.splice(index, 1);
        this.setState({ processedItems: items });
    }

    render() {

        const onBarcodeChange = (event) => {
            if (this.state.selectedProjectID > 0) {
                this.setState({ barcodeText: QRURLRemover(event.target.value) });
            }
            
        }

        if (this.state.selectedProjectID > 0) {
            var textboxDisabled = false;
            this.barcodeTextbox.focus();
        }
        else {
            var textboxDisabled = true;
        }

        return (
            <div>

                {this.state.showQuantityPopup ?
                    <QuantityFormModal closeModal={this.closeQuantityForm} onSubmit={this.quantityFormSubmitted} itemName={this.state.quantityFormItemName} maxAmount={this.state.quantityFormMaxAmount} measurementUnit={this.state.quantityFormMeasurementUnit}/>
                    : null
                }
                <center><h3>Check-out items</h3><br/></center>
                <form onSubmit={this.submitHandler}>


                    <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center", }}>
                        <ProjectSelector onSelection={this.onProjectSelection} />
                        <input onChange={onBarcodeChange} id="barcode" size="30" style={{ marginLeft: "7px", fontSize: "20px", height: "40px" }} autoComplete="off" value={this.state.barcodeText} disabled={this.state.processing} ref={(n) => { this.barcodeTextbox = n; }} />
                            <div style={{ paddingLeft: "5px" }} />
                        <Button type="submit" variant="outline-secondary" disabled={this.state.processing || textboxDisabled}>
                                {this.state.processing ? <Fragment><Spinner animation="border" size="sm" variant="secondary" /> Loading</Fragment> : <Fragment>Submit</Fragment>}  </Button>
                    </div>
                    {this.state.showError ? <center><p style={{ fontSize: "20px", color: "red" }}>{this.state.errorMessage}</p></center> : null }
                </form>
                    {this.renderProcessedTransactions(this.state.processedItems)}
            </div>
        );
    }
}
