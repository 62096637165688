import React from 'react';
import './NavMenu.css';

export class SignInSignOutButton extends React.Component {

    constructor(props) {
        super(props);        
    }

    async onTabletSignout() {
        const response = await fetch(`api/users/tablet-signout`);
        window.location.reload(true);
    }
    
    render() {        

        if (this.props.userData.showAlternateLogin) {
            if (this.props.userData.userLoggedIn) {
                if (this.props.userData.userHasAvatar) {
                    return (
                        <a href="#" onClick={() => this.onTabletSignout()} title={this.props.userData.userDisplayName + " (Tablet Login) Click To sign-out"}><img style={{ borderRadius: "50%", width: "80%" }} src={this.props.userData.userAvatar}  /></a>
                    );
                }
                else
                {
                    return (
                        <a href="#" onClick={() => this.onTabletSignout()} title={this.props.userData.userDisplayName + " (Tablet Login) Click To sign-out"} data-letters={this.props.userData.userInitials}></a>
                    );
                }
            }
            else {
                return (
                    <a className="text-dark nav-link" href="/"> Sign-in</a>
                );
            }
        }
        else {
            if (this.props.userData.userLoggedIn) {
                if (this.props.userData.userHasAvatar) {
                    return (
                        <a href="MicrosoftIdentity/Account/SignOut" title={this.props.userData.userDisplayName + " Click To sign-out"}><img style={{ borderRadius: "50%", width: "80%" }} src={this.props.userData.userAvatar} /></a>
                    );
                }
                else {
                    return (
                        <a href="MicrosoftIdentity/Account/SignOut" title={this.props.userData.userDisplayName + " Click To sign-out"} data-letters={this.props.userData.userInitials} ></a>
                    );
                }
            } else {
                return (
                    <a className="text-dark nav-link" href="/MicrosoftIdentity/Account/SignIn"> Sign-in</a>
                );
            }
        }        
    }
}
